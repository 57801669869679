.team {
  background: var(--color-lightGray);
  padding: 161px 0 231px;

  .column {
    width: 100%;

    &.column--large {
      margin-left: 30px;
      width: calc(100% + 40%);
    }
  }

  .content {
    .category {
      font-family: "Roboto Mono", serif;
      font-size: 18px;
      font-weight: 700;
      color: var(--color-secondary);
      text-transform: uppercase;
    }

    .title {
      font-family: "Roboto Mono", serif;
      font-size: 36px;
      font-weight: 700;
      line-height: 47px;
      color: var(--color-black);
      margin-bottom: 25px;
    }

    .text {
      font-family: "Maven Pro", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: var(--color-text);
      margin-bottom: 40px;
    }

    .button {
      padding: 15px 30px;
      display: initial;
      text-transform: uppercase;

      span {
        position: relative;
        top: 5px;
      }
    }
  }


  .teammates {
    position: relative;
    top: -161px;

    .arch {
      position: absolute;
      top: 0;
      left: 0;

      &.arch_0 {
        transform: scale(0.5);
        left: 400px;
        top: 0;
      }

      &.arch_1 {
        top: 400px;
        transform: scale(0.85)
      }

      &.arch_2 {
        top: 300px;
        left: 600px;
        transform: scale(0.75);
      }

      &.arch_3 {
        top: 50px;
        left: 70px;
      }
    }

    .teammate {
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      object-fit: cover;
      max-width: 300px;
      max-height: 300px;
      filter: grayscale(100%);
      transition: filter 150ms ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0px 67px 80px rgba(0, 0, 0, 0.09), 0px 43.4259px 46.8519px rgba(0, 0, 0, 0.0683333), 0px 25.8074px 25.4815px rgba(0, 0, 0, 0.0546667), 0px 13.4px 13px rgba(0, 0, 0, 0.045), 0px 5.45926px 6.51852px rgba(0, 0, 0, 0.0353333), 0px 1.24074px 3.14815px rgba(0, 0, 0, 0.0216667);

      &.teammate_0 {
        width: 216px;
        height: 216px;
        top: 284px;
        left: 33px;
      }

      &.teammate_1 {
        width: 199px;
        height: 199px;
        top: 84px;
        left: 171px;
      }

      &.teammate_2 {
        width: 215px;
        height: 215px;
        top: 229px;
        left: 383px;
        z-index: 1;
      }

      &.teammate_3 {
        width: 221px;
        height: 221px;
        left: 553px;
        top: 395px;
        z-index: 2;
      }

      &.teammate_4 {
        width: 216px;
        height: 216px;
        top: 30px;
        left: 605px;
      }

      &.teammate_5 {
        width: 265px;
        height: 265px;
        top: 370px;
        left: 210px;
        z-index: 0;
      }

      &.teammate_6 {
        width: 199px;
        height: 199px;
        top: 20px;
        left: 350px;
        z-index: 1;
      }

      &:hover {
        filter: grayscale(0);
      }
    }
  }
}

@media (max-width: 1199px) {
  .team {
    padding: 60px 30px 160px;
    height: 860px;

    .teammates {
      transform: scale(0.7) translateX(-160px);
      top: 0;
    }

    .content {
      .button {
        margin: 0 auto 30px;
        display: table;
        padding: 6px 30px 15px;
      }
    }
  }
}

@media (max-width: 768px) {
  .team {
    .teammates {
      transform: scale(0.5) translateX(-360px);
      top: 0;
    }
  }
}

@media (max-width: 465px) {
  .team {
    .teammates {
      transform: scale(0.45) translateX(-420px);
    }
  }
}
