.customers .slick-list {margin: 0 -30px;}
.customers .slick-slide>div {padding: 0 30px;}
.customers .slick-track {height: 180px}

.cards .slick-list {margin: 0 -15px;}
.cards .slick-slide>div {padding: 0 15px;}
.cards .slick-track {height: 280px}

@media (max-width: 1199px) {
  .cards .slick-dots {
    bottom: 45px;
  }
}

.cards .slick-track {overflow: initial !important}
.cards .slick-list {overflow: initial !important}

.categories .slick-list {margin: 0 -15px;}
.categories .slick-slide>div {padding: 0 15px;}

.services .slick-list {margin: 0 -15px;}
.services .slick-slide>div {padding: 0 15px;}
.services .slick-track {height: 400px}

@media (max-width: 880px) {
  .services .slick-track {
    padding-bottom: 60px;
    height: auto;
  }
}

.slick-dots {
  position: absolute;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  list-style-type: none;
  display: flex !important;
}

.customers .slick-dots {
  bottom: 50px;
}

.services .slick-dots {
  bottom: 30px
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button {
  font-size: 0;
  width: 12px;
  height: 12px;
  background: var(--color-white);
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.slick-dots .slick-active button {
  width: 24px;
  border-radius: 10px;
  background: var(--color-primary);
  transition: all 150ms ease-in-out;
}

.categories .slick-dots li button {
  font-size: 0;
  width: 12px;
  height: 12px;
  background: transparent;
  border: 1px solid var(--color-white);
  border-radius: 50%;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.categories .slick-dots .slick-active button {
  width: 24px;
  border-radius: 10px;
  background: var(--color-white);
  transition: all 150ms ease-in-out;
}

#services .slick-dots li button {
  background: var(--color-black);
  border: 1px solid var(--color-white);
}

#services .slick-dots .slick-active button {
  background: var(--color-white);
}

.home-portfolio .slick-dots {
  position: relative;
  display: flex !important;
  margin: 0 auto;
  justify-content: center;
  margin-top: 15px;
}

@media (max-width: 1199px) {
  #services .slick-dots {
    margin-top: 40px;
  }
}
