.footer {
  padding: 80px 0 30px;

  .row {
    margin: 0 -15px;
  }

  .text__helper {
    font-size: 14px;
    color: var(--color-text);
    margin-top: 20px;
    width: 50%;
    line-height: 22px;
    font-family: "Maven Pro";
    font-weight: 400;
  }

  .column {
    width: 100%;
    margin: 0 15px;
  }

  .columns {
    width: 200%;
    display: flex;

    .column {
      width: 100%;

      &__title {
        font-weight: 700;
        font-family: "Roboto Mono", serif;
        font-size: 18px;
        margin-bottom: 38px;
        color: var(--color-black);
      }

      ul {
        padding: 0;
        list-style-type: none;

        li {
          a {
            font-size: 16px;
            margin-bottom: 15px;
            display: table;
            font-family: "Roboto Mono", serif;
            color: var(--color-text);

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .social {
    display: flex;
    padding: 0;
    list-style-type: none;
    margin: 0 -7.5px;

    li {
      a {
        width: 45px;
        height: 45px;
        background: var(--color-primary);
        border-radius: 50%;
        box-shadow: 0px 2px 10px rgba(58, 138, 227, 0.22);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 7.5px;
        transition: background 150ms ease-in-out;

        &:hover {
          background: var(--color-secondary);
        }
      }
    }
  }

  .disclaimer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 0;

    p {
      font-family: "Maven Pro", sans-serif;
      font-size: 16px;
      font-weight: 400;
      strong {
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 1199px) {
  .footer {
    padding: 40px 0 0;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 0;

      * {
        flex: 100%;
        text-align: center;
      }

      p {
        margin-bottom: 60px;
      }
    }

    .column {
      margin: 0;
    }

    .columns {
      display: block;
      margin: 0 auto;
      width: 100%;

      .column {
        text-align: center;

        ul {
          margin-bottom: 60px;

          li {
            a {
              text-align: center;
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }

  .disclaimer {
    padding-bottom: 30px;
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
