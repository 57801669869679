.header {
  position: relative;
  margin-top: 60px;
  margin-bottom: 50px;

  .column {
    width: 100%;

    h1 {
      font-family: "Roboto Mono", serif;
      color: var(--color-black);
      font-size: 36px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    p {
      font-family: "Maven Pro", sans-serif;
      color: var(--color-text);
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      width: 60%;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -15px;

    button {
      background: var(--color-white);
      width: 62px;
      height: 62px;
      border-radius: 10px;
      box-shadow: 0px 115.966px 92.7731px rgba(0, 0, 0, 0.05), 0px 75.1634px 54.3324px rgba(0, 0, 0, 0.04), 0px 44.6685px 29.55px rgba(0, 0, 0, 0.02), 0px 23.1933px 15.0756px rgba(0, 0, 0, 0.01), 0px 9.44911px 7.55929px rgba(0, 0, 0, 0.01), 0px 2.14753px 3.65079px rgba(0, 0, 0, 0.008);
      border: none;
      margin: 0 15px;
      cursor: pointer;
      transition: background 150ms ease, color 150ms ease;

      svg {
        path {
          fill: var(--color-primary);
        }
      }

      &.active {
        background: var(--color-primary);

        svg {
          path {
            fill: var(--color-white);
          }
        }
      }

      &:hover {
        background: var(--color-primary);

        svg {
          path {
            fill: var(--color-white);
          }
        }
      }
    }
  }
}

.body {
  position: relative;
  padding: 0 0 60px 0;

  .sidebar {
    width: 30%;
    margin-right: 60px;

    @media (max-width: 1199px) {
      margin-right: 0;
    }

    .list {
      list-style-type: none;
      padding: 0;

      li {
        background: var(--color-white);
        cursor: pointer;
        padding: 30px 0;

        p {
          font-family: "Roboto Mono", serif;
          color: var(--color-secondary);
          font-size: 18px;
          line-height: 23px;
          font-weight: 700;

          span {
            font-size: 20px;
            transition: margin-left 150ms ease-in-out;
          }
        }

        &.active {
          box-shadow: -71.2732px 49.8912px 80px rgba(0, 0, 0, 0.04), -36.082px 25.2574px 34.875px rgba(0, 0, 0, 0.027), -14.2546px 9.97824px 13px rgba(0, 0, 0, 0.02), -3.1182px 2.18274px 4.625px rgba(0, 0, 0, 0.013);
          position: relative;
          z-index: 1;

          &::after {
            content: "";
            width: 100vw;
            height: 100%;
            position: absolute;
            left: -100vw;
            top: 0;
            background: var(--color-white);
          }
        }

        &:hover {
          p {
            span {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .projects {
    position: relative;
    margin-top: 25px;
    width: 70%;

    .project {
      display: block;
      padding: 11px 0;
      width: 100%;
      height: auto;
      border-bottom: 1px solid var(--color-lightGray);
      cursor: pointer;
      margin-bottom: 22px;
      position: relative;

      &__category {
        text-transform: uppercase;
        color: var(--color-gray);
        font-size: 12px;
        line-height: 16px;
        font-family: "Maven Pro", sans-serif;
        margin-bottom: 6px;
      }

      &__title {
        color: var(--color-text);
        font-size: 24px;
        line-height: 31px;
        font-weight: 700;
        font-family: "Roboto Mono", serif;
        transition: color 150ms ease;
      }

      .arrow {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: right 150ms ease-in-out;
      }

      &:hover {
        .project__title {
          color: var(--color-primary);
        }

        .arrow {
          right: 15px;
        }
      }
    }

    &.project__images {
      display: flex;
      flex-wrap: wrap;

      .project {
        flex: calc(50% - 2px);
        background-size: cover;
        background-repeat: no-repeat;
        margin: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 15px;
        height: 300px;
        max-width: calc(50% - 2px);

        &__category,
        &__title {
          color: var(--color-white);
          position: relative;
          z-index: 1;
          transition: transform 150ms ease-in-out;
          transform: translateY(10px);
          opacity: 0;
        }

        &__title {
          margin-bottom: 30px;
        }

        .arrow {
          position: relative;
          right: initial;
          top: initial;
          transform: none;
          border: 1px solid var(--color-white);
          border-radius: 50%;
          width: 50px;
          height: 50px;
          margin: 0 auto;
          padding: 15px;
          transition: all 150ms ease-in-out;
          transform: translateY(10px);
          opacity: 0;

          path {
            fill: var(--color-white);
          }

          &:hover {
            background: var(--color-white);

            path {
              fill: var(--color-black);
            }
          }
        }

        &::before {
          content: "";
          background: rgba(0,0,0,0.5);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          z-index: 0;
          transition: opacity 150ms ease-in-out;
          opacity: 0;
          pointer-events: none;
        }

        &:hover {
          .project__title {
            color: var(--color-white);
          }

          .project__title,
          .project__category,
          .arrow {
            transform: translateY(0);
            opacity: 1;
          }

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}

.modal {
  position: fixed;
  z-index: 9999;
  width: 924px;
  max-height: 80vh;
  background: var(--color-white);
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  padding: 50px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 115.966px 92.7731px rgba(0, 0, 0, 0.05), 0px 75.1634px 54.3324px rgba(0, 0, 0, 0.04), 0px 44.6685px 29.55px rgba(0, 0, 0, 0.02), 0px 23.1933px 15.0756px rgba(0, 0, 0, 0.01), 0px 9.44911px 7.55929px rgba(0, 0, 0, 0.01), 0px 2.14753px 3.65079px rgba(0, 0, 0, 0.008);
  transition: opacity 150ms ease-in-out, transform 150ms cubic-bezier(0.2, 0, 0, 1.2);
  transition-delay: 150ms;
  overflow-y: auto;

  @media (max-width: 992px) {
    width: calc(100% - 30px);
    padding: 30px;
  }

  ~ .modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.15);
    z-index: 9998;
    opacity: 0;
    pointer-events: none;
    transition: opacity 150ms ease-in-out;
  }

  &.active {
    opacity: 1;
    pointer-events: initial;
    transform: translate(-50%, -50%);

    ~ .modal__backdrop {
      pointer-events: initial;
      opacity: 1;
    }
  }

  &__head {
    position: relative;
    display: block;
    width: 100%;

    .category {
      font-family: "Maven Pro", sans-serif;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-weight: 500;
      color: var(--color-gray);
      margin-bottom: 6px;
    }

    .title {
      font-family: "Roboto Mono", serif;
      color: var(--color-primary);
      font-weight: 700;
      line-height: 36px;
      font-size: 28px;
      margin-bottom: 30px;
    }

    .description {
      font-size: 16px;
      color: var(--color-text);
      font-family: "Maven Pro", sans-serif;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 30px;
    }

    button {
      background: var(--color-white);
      width: 62px;
      height: 62px;
      border-radius: 10px;
      box-shadow: 0px 115.966px 92.7731px rgba(0, 0, 0, 0.05), 0px 75.1634px 54.3324px rgba(0, 0, 0, 0.04), 0px 44.6685px 29.55px rgba(0, 0, 0, 0.02), 0px 23.1933px 15.0756px rgba(0, 0, 0, 0.01), 0px 9.44911px 7.55929px rgba(0, 0, 0, 0.01), 0px 2.14753px 3.65079px rgba(0, 0, 0, 0.008);
      border: none;
      cursor: pointer;
      transition: background 150ms ease, color 150ms ease;
      position: absolute;
      top: -30px;
      right: -30px;

      &:hover {
        background: var(--color-primary);
        svg {
          path {
            fill: var(--color-white);
          }
        }
      }

      @media (max-width: 991px) {
        width: 42px;
        height: 42px;
        top: -20px;
        right: -20px;

        svg {
          transform: scale(0.7);
        }
      }
    }
  }

  &__body {
    .image {
      width: 100%;
      height: auto;
      max-height: 500px;
      min-height: 500px;
      object-fit: contain;
      border-radius: 10px;
    }
  }
}

.alert {
  margin-top: 25px;

  h2 {
    font-family: "Roboto Mono", serif;
    color: var(--color-black);
    font-size: 36px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  span {
    font-family: "Roboto Mono", serif;
    color: var(--color-black);
    font-size: 18px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 30px;
    display: block;
  }

  p {
    font-family: "Maven Pro", sans-serif;
    color: var(--color-text);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
}

@media (max-width: 1199px) {
  .header {
    padding: 140px 30px 0;
    margin: 0;
    width: 100%;

    .column {
      p {
        width: 100%;
      }
    }
  }

  .body {
    padding: 30px 30px 60px;
    margin-bottom: 30px;

    .sidebar {
      width: 100%;

      li {
        text-align: center;

        &.active {
          border: 1px solid rgba(0,0,0,0.075);
          box-shadow: none !important;

          &::after {
            display: none;
          }
        }
      }
    }

    .projects {
      width: 100%;

      h1 {
        padding-right: 60px;
      }

      &.project__images {
        .project {
          height: 300px;

          &__category,
          &__title {
            display: none !important;
          }
        }
      }
    }
  }

  .buttons {
    display: block;
    transform: scale(0.9);
    margin-top: 30px !important;

    button {
      width: 100% !important;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .alert {
    text-align: center;
    padding: 0 60px;
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .body {
    .projects {
      &.project__images {
        .project {
          height: 200px;
        }
      }
    }
  }
}
