.services {
  background: var(--color-black);
  padding: 120px 0 60px;
  position: relative;

  @media (max-width: 1199px) {
    padding: 60px 30px 30px;
  }

  .category {
    color: var(--color-text);
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 13px;
    text-align: center;
  }

  .title {
    font-size: 36px;
    font-family: "Roboto Mono", serif;
    font-weight: 700;
    color: var(--color-white);
    line-height: 48px;
    text-align: center;
    margin-bottom: 90px;
  }

  &__list {
    display: flex;
    width: 100%;
    justify-content: center;

    @media (max-width: 1199px) {
      display: initial;
    }

    .service {
      width: 100%;
      text-align: center;
      position: relative;
      padding: 0 30px;

      .icon {
        width: 100%;
        height: 112px;
        position: relative;
        z-index: 1;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          path {
            fill: var(--color-secondary);
            transition: all 150ms ease-in-out;
          }
        }
      }

      h1 {
        color: var(--color-secondary);
        font-size: 18px;
        font-weight: 700;
        font-family: "Roboto Mono", serif;
        padding-top: 50px;
        margin-bottom: 18px;
        line-height: 24px;
        position: relative;
        z-index: 1;
        transition: color 150ms ease-in-out;
      }

      p {
        color: var(--color-text);
        font-family: "Maven Pro", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        position: relative;
        z-index: 1;
        transition: color 150ms ease-in-out;
      }

      &::after,
      &::before {
        content: "";
        left: 50%;
        top: 50%;
        position: absolute;
        background: linear-gradient(180deg, rgba(25, 25, 25, 0) 48.49%, #191919 173.89%);
        border-radius: 36px;
        width: calc(100% - 15px);
        height: calc(100% + 60px);
        transform: translate(-50%, -50%);
        opacity: 0;
      }

      &::after {
        transition: opacity 150ms ease-in-out;
      }

      &::before {
        transition: all 150ms ease-in-out;
        transition-delay: 50ms;
      }

      &:hover {
        h1,
        p {
          color: var(--color-white);
        }

        svg {
          path {
            fill: var(--color-white);
          }
        }

        &::after {
          opacity: 1;
        }

        &::before {
          opacity: 1;
          left: calc(50% - 15px);
          top: calc(50% + 15px);
        }
      }
    }
  }

  .btncustom {
    font-family: "Roboto Mono", serif;
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    width: auto;
    display: table;
    margin: 100px auto 0;

    span {
      font-size: 28px;
      margin-left: 5px;
      position: relative;
      top: 1px;
      transition: margin-left 150ms ease-in-out;
    }

    &::after {
      content: "";
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      background: var(--color-white);
      position: absolute;
      transition: width 150ms ease-in-out;
    }

    &:hover {
      &::after {
        width: calc(100% - 60px);
      }

      span {
        margin-left: 10px;
      }
    }
  }

  .smile {
    position: absolute;
    bottom: -30px;
    z-index: 1;
  }
}

@media (max-width: 768px) {
  .smile {
    display: none;
  }
}
