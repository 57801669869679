.portfolio {
  padding: 130px 0;
  height: 648px;
  position: relative;

  .mosaic {
    width: 100%;
    margin-top: -130px;
    height: 0;

    .group {
      height: 324px;
      overflow: hidden;
    }

    .image {
      position: relative;
      width: 100%;
      height: 100%;
      height: 324px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom;

      &--large {
        width: calc(100% + 50%);
      }

      .content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, calc(-50% + 20px));
        text-align: center;
        z-index: 2;
        opacity: 0;
        transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;

        .category {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 3px;
          line-height: 18px;
          color: var(--color-white);
          font-family: "Roboto Mono", serif;
          text-transform: uppercase;
        }

        .title {
          font-family: "Roboto Mono", serif;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          color: var(--color-white);
          margin-top: 5px;
        }

        .button {
          width: 75px;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          border: 1px solid var(--color-white);
          border-radius: 50%;
          margin-top: 30px;
          transition: background 150ms ease, color 150ms ease;

          svg {
            transform: scale(1.7);
          }

          &:hover {
            background: var(--color-white);

            svg {
              path {
                fill: var(--color-black);
              }
            }
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 150ms ease-in-out;
      }

      &:hover {
        .content {
          transform: translate(-50%, -50%);
          opacity: 1;
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .mosaic__holder {
    width: 100%;
    height: 0;
  }

  .column {
    width: 100%;
    padding: 0 130px;

    .category {
      font-family: "Roboto Mono", serif;
      font-size: 18px;
      font-weight: 700;
      color: var(--color-secondary);
      text-transform: uppercase;
    }

    .title {
      font-family: "Roboto Mono", serif;
      font-size: 36px;
      font-weight: 700;
      line-height: 47px;
      color: var(--color-black);
      margin-bottom: 25px;
    }

    .text {
      font-family: "Maven Pro", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: var(--color-text);
      margin-bottom: 40px;
    }
  }

  .button {
    padding: 15px 30px;
    display: initial;

    span {
      position: relative;
      top: 5px;
    }
  }

  .portfolioMobile {
    display: none;
  }
}

@media (max-width: 1199px) {
  .portfolio {
    padding: 60px 0;
    height: auto;

    .mosaic {
      display: none;
    }

    .column {
      padding: 0 30px;
    }

    .button {
      margin: 0 auto;
      display: table;
      padding: 6px 30px 15px;
    }

    .portfolioMobile {
      display: block;
      overflow: hidden;
      margin-bottom: 60px;
      position: relative;
      z-index: 1;

      img {
        object-fit: cover;
        height: 300px;
        width: 100%;
      }
    }
  }
}
