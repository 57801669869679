/***********************
	Colors
***********************/
:root {
	--color-black: #000;
	--color-white: #FFF;
	--color-gray: #C4C4C4;
  --color-lightGray: #E1E1E1;
	--color-primary: #3A8AE3;
  --color-secondary: #1EAFCD;
  --color-text: #888888;
}

/***********************
	Reset
***********************/
html, body {
  overflow-x: hidden;
}
main {
  overflow: hidden;
}

body {
  margin: 0;
}

body * {
  box-sizing: border-box;
  outline: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
textarea,
input {
  margin: 0;
}

/***********************
	Buttons
***********************/
.button {
  cursor: pointer;
  font-family: "Maven Pro", sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding: 17px;
  border-radius: 10px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
}

.button span {
  font-size: 28px;
  margin-left: 5px;
  position: relative;
  top: 1px;
  transition: margin-left 150ms ease-in-out;
}

.button.button--uppercase {
  text-transform: uppercase;
}

.button.button--blue {
  background: var(--color-primary);
  color: var(--color-white);
  transition: background 150ms ease;
  box-shadow: 0px 20px 35px rgba(58, 138, 227, 0.15);
}

.button.button--white {
  background: var(--color-white);
  color: var(--color-primary);
  transition: background 150ms ease;
  box-shadow: 0px 20px 35px rgba(58, 138, 227, 0.15);
}

.button.button--blue-outlined {
  background: var(--color-white);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  transition: background 150ms ease;
  box-shadow: 0px 20px 35px rgba(58, 138, 227, 0.15);
  font-weight: 500;
}

.button.button--blue-outlined:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.button.button--white:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.button.button--color-white {
  background: none;
  color: var(--color-white);
}

.button.button--blue:hover {
  background: var(--color-secondary);
}

/***********************
	Helpers
***********************/
.flex {
  display: initial;
}

.align-start {
  align-items: flex-start;
}

@media (min-width: 1200px) {
  .flex {
    display: flex;
  }
}



/***********************
	Animations
***********************/
.animation-jump--fast {
  animation: 1s ease-in-out 0s infinite alternate jump;
}

.animation-jump--slow {
  animation: 1s ease-in-out 700ms infinite alternate jump;
}

.animation-jump--slow-alternate {
  animation: 1s ease-in-out 450ms infinite alternate jump;
}

.animation-tm0 {
  animation: 1.5s ease-in-out 0ms infinite alternate jump;
}

.animation-tm1 {
  animation: 1.5s ease-in-out 150ms infinite alternate jump;
}

.animation-tm2 {
  animation: 1.5s ease-in-out 300ms infinite alternate jump;
}

.animation-tm3 {
  animation: 1.5s ease-in-out 450ms infinite alternate jump;
}

.animation-tm4 {
  animation: 1.5s ease-in-out 600ms infinite alternate jump;
}

.animation-tm5 {
  animation: 1.5s ease-in-out 750ms infinite alternate jump;
}

.animation-tm6 {
  animation: 1.5s ease-in-out 900ms infinite alternate jump;
}

.animation-bg {
  animation: 500s linear 0ms infinite runBackground;
}

@keyframes jump {
  from {
    tranform: translateY(-5px)
  }
  to {
    transform: translateY(5px)
  }
}

@keyframes runBackground {

  to {
    background-position: 10000px center;
  }
}

/***********************
	Container
***********************/
.container {
  width: 100%;
  display: block;
  margin: 0 auto;
  position: relative;
}

.container--full {
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1119px) {
  .container {
    width: 968px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1120px;
  }
}
