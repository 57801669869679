.location {
  padding: 90px 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -30px;
  box-shadow: inset 0 4px 8px 0 rgba(0,0,0,0.15), inset 0 -4px 8px 0 rgba(0,0,0,0.15);

  .box {
    border-radius: 10px;
    background: var(--color-black);
    box-shadow: 0px 31px 65px rgba(0, 0, 0, 0.22), 0px 15.6938px 28.3359px rgba(0, 0, 0, 0.14), 0px 6.2px 10.5625px rgba(0, 0, 0, 0.095), 0px 1.35625px 3.75781px rgba(0, 0, 0, 0.06175);
    max-width: 369px;
    padding: 28px 40px;
    position: relative;

    &::after {
      content: "";
      width: 56px;
      height: 7px;
      background: var(--color-primary);
      position: absolute;
      top: 0;
      left: 40px;
    }

    .title,
    p {
      font-family: "Maven Pro", sans-serif;
      font-weight: 400;
      line-height: 18px;
      color: var(--color-white);
    }

    .title {
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 22px;
    }

    p {
      margin-bottom: 15px;
    }

    p strong {
      font-weight: 700;
    }
  }
}

@media (max-width: 1199px) {
  .location {
    padding: 90px 30px;

    .box {
      width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
}
