.header {
  position: relative;
}

.wrapper {
  justify-content: center;
  align-items: center;
  position: relative;

  .content {
    width: 100%;
    margin-right: 115px;

    .category {
      text-transform: uppercase;
      color: var(--color-primary);
      font-size: 18px;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      margin: 0 0 5px;
    }

    .title {
      color: var(--color-black);
      font-size: 64px;
      font-weight: 700;
      font-family: "Roboto Mono", serif;
      line-height: 84px;
      margin: 0 0 15px;
    }

    .text {
      color: var(--color-text);
      font-family: "Maven Pro", sans-serif;
      font-size: 18px;
      font-weight: 400;
      margin: 0 0 60px;
      line-height: 32px;
    }

    .contact {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;

      .dropdown {
        position: relative;
        padding: 18px;
        box-shadow: 0px 20px 35px rgba(58, 138, 227, 0.15);
        border: 1px solid var(--color-gray);
        border-radius: 10px;
        width: 100%;
        margin-right: 15px;
        cursor: pointer;

        .label {
          font-size: 18px;
          color: var(--color-text);
          font-weight: 500;
          font-family: "Maven Pro", sans-serif;

          .chevron {
            margin-left: 10px;
            float: right;
            position: relative;
            top: 7px;
            fill: var(--color-gray);
            transition: transform 300ms ease-in-out;

            path {
              stroke: var(--color-gray);
            }
          }
        }

        ul {
          height: 0;
          overflow: hidden;
          padding: 0;
          margin: 0;
          position: absolute;
          top: 48px;
          border: 1px solid var(--white);
          border-top: none;
          width: 100%;
          left: 0;
          padding: 3px 0;
          list-style-type: none;
          box-sizing: border-box;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          background: var(--color-white);
          color: var(--color-text);
          font-weight: 500;
          transition: height 300ms ease-in-out;

          li {
            font-size: 18px;
            font-family: "Maven Pro", sans-serif;
            padding: 15px 18px;
            font-weight: 400;
            transition: background 100ms ease-in-out, color 100ms ease-in-out;
            pointer-events: none;
            display: flex;
            align-items: center;

            svg {
              margin-right: 15px;

              path {
                transition: fill 100ms ease-in-out;
              }
            }

            &:hover {
              background: var(--color-primary);
              color: var(--color-white);

              svg {
                path {
                  fill: var(--color-white);
                }
              }
            }

            &:first-child {
              border-top: 1px solid var(--color-white);
            }

            &:not(:last-child) {
              border-bottom: 1px solid var(--color-white);
            }
          }
        }

        &--active {
          .chevron {
            transform: rotate(-180deg);
          }

          ul {
            height: 238px;
            border: 1px solid var(--color-gray);
            border-top: none;
            width: calc(100% + 2px);
            left: -1px;

            li {
              pointer-events: initial;

              &:first-child {
                border-top: 1px solid var(--color-gray);
              }

              &:not(:last-child) {
                border-bottom: 1px solid var(--color-gray);
              }
            }
          }
        }
      }

      a[class*="button"] {
        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .image {
    width: 100%;
    position: relative;

    &__block {
      width: 700px;
      height: 700px;
      margin-right: -250px;
      display: block;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      @media (max-width: 767px) {
        width: 600px;
        height: 600px;
      }

      @media (max-width: 668px) {
        width: 500px;
        height: 500px;
      }

      @media (max-width: 556px) {
        width: 400px;
        height: 400px;
      }

      @media (max-width: 459px) {
        width: 300px;
        height: 300px;
      }

      @media (max-width: 357px) {
        width: 240px;
        height: 240px;
      }
    }

    .smile,
    .ellipse {
      position: absolute;
    }

    .smile {
      left: -120px;
      top: 105px;

      @media (max-width: 667px) {
        left: -200px;
      }

      @media (max-width: 340px) {
        display: none;
      }
    }

    .ellipse {
      right: -100px;
      top: 50px;

      @media (max-width: 628px) {
        display: none;
      }
    }
  }
}

.ellipse--white {
  position: absolute;
  left: calc(50%);
  //transform: translateX(-50%);
  bottom: -70px;
  z-index: 1;

  @media (max-width: 1199px) {
    display: none;
  }
}

@media (max-width: 1199px) {
  .header {
    padding: 60px 30px 0;
    margin-top: 70px;

    .wrapper {
      .content {
        margin-bottom: 60px;

        .title {
          font-size: 50px;
          line-height: 61px;
          margin: 0 0 30px;
        }
      }

      .image {
        &__block {
          display: block;
          margin: 0 auto;
        }
      }

      .ellipse {
        right: -50px;
      }

      .contact {
        display: block;

        .dropdown {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .header {
    .wrapper {
      .content {
        .title {
          font-size: 49px;
        }
      }
    }
  }
}

@media (max-width: 412px) {
  .header {
    .wrapper {
      .content {
        .title {
          font-size: 40px;
          line-height: 53px;
        }
      }
    }
  }
}

@media (max-width: 348px) {
  .header {
    .wrapper {
      .content {
        .title {
          font-size: 36px;
          line-height: 50px;
        }
      }
    }
  }
}
