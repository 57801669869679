.navbar {
  width: 100%;
  background: var(--color-white);
  padding: 45px 0;
  position: relative;
  z-index: 10;

  .burger {
    display: none;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      path {
        fill: var(--color-black);
      }
    }
  }

  .logo {
    width: 100%;
    display: flex;
    align-items: center;

    a {
      margin-top: 2px;
    }
  }

  .menu {
    display: flex;
    padding: 0;
    list-style-type: none;
    position: relative;
    width: 100%;
    list-style-type: none;

    li {
      position: relative;

      a {
        padding: 30px;
        font-family: "Roboto Mono", serif;
        font-size: 18px;
        color: var(--color-black);
        font-weight: 700;
      }
    }
  }

  .bar {
    display: block;
    height: 3px;
    background: var(--color-primary);
    position: absolute;
    bottom: -10px;
    left: 0;
    transition: left .5s cubic-bezier(0.2, 0, 0, 1.2), width .5s ease;
    opacity: 0;
    width: 0;
  }

  .lang {
    position: relative;
    padding: 15px;
    background: var(--color-white);
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.05), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.04), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.02), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.01), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.008);
    border-radius: 10px;
    font-family: "Roboto Mono", serif;
    font-size: 17px;
    font-weight: 700;
    cursor: pointer;

    span {
      color: var(--color-primary);
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-left: 8px;
        transition: transform 150ms ease-in-out;
      }
    }

    ul {
      height: 0;
      width: 100%;
      padding: 0;
      margin: 0;
      top: 50px;
      left: 0;
      overflow: hidden;
      transition: height 150ms ease-in-out;
      position: absolute;
      background: var(--color-white);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      li {
        display: block;
        width: 100%;
        text-align: left;
        padding: 5px 15px;
        transition: color 150ms ease, background 150ms ease;

        &:hover {
          background: var(--color-primary);
          color: var(--color-white);
        }
      }
    }

    &--active {
      span {
        svg {
          transform: rotate(-180deg);
        }
      }

      ul {
        height: 70px;
        border-top: 1px solid rgba(0,0,0,0.03);
      }
    }
  }

  .mobile__backdrop {
    width: 100vw;
    height: calc(100vh - 91px);
    position: fixed;
    background: rgba(0,0,0,0.75);
    z-index: 0;
    top: 91px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 150ms ease-in-out;

    &--active {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

.disabled {
  background: var(--color-lightGray);
  color: var(--color-text);
  pointer-events: none;
}

@media (max-width: 1199px) {
  .navbar {
    padding: 25px 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.02), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.01), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.008);

    .burger {
      display: block;
    }

    .logo {
      display: block;
      margin: 0 auto;
      text-align: center;
    }

    .menu {
      position: absolute;
      padding: 0;
      top: 49px;
      z-index: 1;
      background: var(--color-white);
      display: initial;
      box-shadow: 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.02), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.01), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.008);
      height: 0;
      overflow: hidden;
      transition: all .3s cubic-bezier(0.2, 0, 0, 1.2);
      transition-delay: 150ms;

      li {
        display: block;
        text-align: center;

        a {
          display: block;
          padding: 15px 0;
        }
      }

      &.menu__active {
        height: 192px;
        padding: 15px 0;
      }
    }

    .lang {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
    }

    .bar {
      display: none;
    }
  }
}
