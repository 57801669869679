.team {
  .banner {
    width: 100%;
    height: 520px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .bannerMobile {
    display: none;
  }

  .titles {
    position: relative;
    width: 70%;
    height: auto;
    transform: translateY(-50%);

    .page__title {
      color: var(--color-black);
      font-size: 85px;
      font-weight: 700;
      font-family: "Roboto Mono", serif;
      line-height: 80px;
      top: 0;
      left: 0;
      text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
    }
  }

  .ellipse {
    position: absolute;
    bottom: -50px;
    left: -120px;
  }

  .smile {
    position: absolute;
    right: -90px;
    top: -130px;
  }

  .content {
    display: block;
    width: 992px;
    margin: -40px auto 60px;


    p {
      margin-bottom: 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      font-family: "Maven Pro", sans-serif;
      color: var(--color-text);
    }
  }

  .cards {
    .card {
      border-radius: 10px;
      border: 1px solid var(--color-secondary);
      padding: 30px;
      box-shadow: 0px 20px 35px rgba(30, 175, 205, 0.15);
      min-height: 180px;
      display: flex !important;
      justify-content: center;
      align-items: center;

      p {
        color: var(--color-secondary);
        font-family: "Maven Pro", sans-serif;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;

        strong {
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
  }

  .cats {
    background: var(--color-primary);
    padding: 127px 0;
    background-repeat: repeat;
    background-size: 10%;
    background-position: center center;

    .cat {
      .number {
        margin-right: 15px;
        color: var(--color-black);
        font-size: 36px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-top: 15px;
      }

      div {
        width: 100%;
        text-align: center;

        img {
          object-fit: cover;
          border-top-left-radius: 50%;
          border-top-right-radius: 50%;
          //border-radius: 50%;
          //filter: grayscale(1);
          transition: filter 150ms ease-in-out;
          width: 300px;
          height: 300px;
          display: block;
          margin: 0 auto 15px;
          box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
        }

        h1 {
          font-size: 24px;
          color: var(--color-white);
          font-family: "Roboto Mono", serif;
          font-weight: 600;
          line-height: 36px;
          margin-bottom: 8px;
        }

        p {
          font-family: "Maven Pro", sans-serif;
          font-size: 16px;
          line-height: 28px;
          font-weight: 400;
          color: var(--color-white);
          margin-bottom: 30px;
        }
      }
    }
  }

  .services {
    padding: 90px 0;
    margin-bottom: 60px;

    &__logo {
      position: absolute;
      left: -100px;
    }

    &__category,
    &__title {
      text-align: center;
    }

    &__category {
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      color: var(--color-primary);
      margin-bottom: 8px;
    }

    &__title {
      font-family: "Roboto Mono", serif;
      font-size: 56px;
      color: var(--color-black);
      font-size: 56px;
      line-height: 73px;
      margin-bottom: 90px;
    }

    &__cards {
      position: relative;

      @media(min-width: 1200px) {
        &::after {
          content: "";
          position: absolute;
          right: -30px;
          top: 0;
          height: 100%;
          width: 200px;
          background: linear-gradient(to right, rgba(0,0,0,0) 0%, var(--color-white) 100%);
          z-index: 1;
          pointer-events: none;
        }

        &::before {
          content: "";
          position: absolute;
          left: -30px;
          top: 0;
          height: 100%;
          width: 200px;
          background: linear-gradient(to right, var(--color-white) 0%, rgba(0,0,0,0) 100%);
          z-index: 1;
          pointer-events: none;
        }
      }

      .service {
        padding: 55px;
        border-radius: 10px;
        border: 1px solid var(--color-lightGray);
        box-shadow: 0px 30.7027px 53.7297px rgba(175, 175, 175, 0.15);
        background: var(--color-white);
        position: relative;
        overflow: hidden;

        @media (max-width: 1199px) {
          padding: 20px;
        }

        .role {
          font-size: 24px;
          font-weight: 600;
          margin-top: -10px;
          margin-bottom: 15px;

          @media (max-width: 1199px) {
            font-size: 18px;
            line-height: 24px;
          }
        }

        .logo {
          position: absolute;
          top: -50px;
          left: -50px;
          opacity: 0.3;
        }

        .title {
          color: var(--color-secondary);
          margin-bottom: 16px;
          font-family: "Roboto Mono", serif;
          font-size: 44px;
          line-height: 58px;
          font-weight: 700;
          position: relative;
          z-index: 1;
          word-break: break-word;

          @media (max-width: 1199px) {
            font-size: 28px;
            line-height: 36px;
          }
        }

        p {
          font-size: 16px;
          line-height: 28px;
          color: var(--color-text);
          font-family: "Maven Pro", sans-serif;
          font-weight: 400;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .team {
    padding-top: 91px;

    .banner {
      display: none;
    }

    .bannerMobile {
      display: block;
      width: 100%;
      height: auto;
    }

    .titles {
      padding: 0 30px;
      width: 100%;
      position: initial;
      margin-bottom: 30px;

      .page__title {
        font-size: 38px;
        line-height: 52px;
        text-align: center;
      }
    }

    .content {
      padding: 0 30px;
      width: 100%;
      font-size: 16px;
    }
  }

  .cards {
    padding: 0 30px;
  }

  .cats {
    padding: 0 30px;
  }

  .ellipse, .smile {
    display: none;
  }
}
